import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SectionTitle from "../SectionTitle"
import { useTranslation } from "react-i18next"
import { Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"

const MobileIndex = () => {
  const { t } = useTranslation()

  const scrollTo = event => {
    event.preventDefault()
    const el = document.getElementById("mobile-first")
    el.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="md:hidden">
      <div className="relative w-full">
        <Swiper modules={[Autoplay]} autoplay>
          <SwiperSlide>
            <StaticImage
              src="../../images/Mobile/02.m_main_banner.png"
              alt="banner"
              placeholder="blurred"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../images/Mobile/banner2.jpg"
              alt="banner2"
              placeholder="blurred"
            />
          </SwiperSlide>
        </Swiper>
        <a
          href="#first"
          onClick={scrollTo}
          className="absolute down-arrow p-6 z-10"
          style={{ bottom: "5%", left: "5%" }}
        >
          <StaticImage
            src="../../images/icons/down.png"
            alt="down"
            width={27}
            placeholder="blurred"
          />
        </a>
      </div>
      <div className="bg-black w-full" style={{ height: 100 }} />
      <StaticImage
        src="../../images/Mobile/02.banner_1.png"
        alt="banner1"
        placeholder="blurred"
        id="mobile-first"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("index.title1")} />
        <p
          className="font-light text-sm leading-6 text-center mb-10"
          style={{ maxWidth: 250, letterSpacing: "0.2em" }}
        >
          <span className="block">{t("index.title1Des1")}</span>
          <span className="block">{t("index.title1Des2")}</span>
          <span className="block">{t("index.title1Des3")}</span>
        </p>
        <Link
          className="bg-black py-4 rounded-md text-center text-white text-sm"
          style={{ width: 300, letterSpacing: "0.3em" }}
          to="/about"
        >
          {t("index.title1Link")}
        </Link>
      </div>
      <StaticImage
        src="../../images/Mobile/05.banner_4.png"
        alt="banner4"
        placeholder="blurred"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("index.title2Des1")} />
        <p
          className="font-light text-sm leading-6 text-center mb-10"
          style={{ maxWidth: 250, letterSpacing: "0.2em" }}
        >
          <span className="block">{t("index.title2Des2")}</span>
          <span className="block">{t("index.title2Des3")}</span>
          <span className="block">{t("index.title2Des4")}</span>
        </p>
        <Link
          className="bg-black py-4 rounded-md text-center text-white text-sm"
          style={{ width: 300, letterSpacing: "0.3em" }}
          to="/sorghum"
        >
          {t("index.title2Link")}
        </Link>
      </div>
      <StaticImage
        src="../../images/Mobile/02.banner_3.png"
        alt="banner3"
        placeholder="blurred"
      />
      <div className="flex flex-col justify-center items-center px-12 py-24">
        <SectionTitle title={t("index.title3")} />
        <p
          className="font-light text-sm leading-6 text-center mb-10"
          style={{ maxWidth: 250, letterSpacing: "0.2em" }}
        >
          <span className="block">{t("index.title3Des1")}</span>
        </p>
        <Link
          className="bg-black py-4 rounded-md text-center text-white text-sm"
          style={{ width: 300, letterSpacing: "0.3em" }}
          to="/location"
        >
          {t("index.title3Link")}
        </Link>
      </div>
    </div>
  )
}

export default MobileIndex
