import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DesktopIndex from "../components/DesktopIndex"
import MobileIndex from "../components/MobileIndex"

const IndexPage = () => (
  <>
    <Seo
      title="布蘭登公爵｜中式白酒"
      description="布蘭登公爵將把新型態中式白酒推進世界頂端 ，打破中式白酒-高粱的傳統印象。凍蒜高粱只是布蘭登公爵的開端，將挖掘更多顛覆味蕾的極佳風味。類型：中式白酒、高粱"
    />
    <DesktopIndex />
    <MobileIndex />
  </>
)

export default IndexPage
