import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"

const DesktopIndex = () => {
  const { t } = useTranslation()

  const scrollTo = event => {
    event.preventDefault()
    const el = document.getElementById("first")
    el.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="hidden md:block">
      <div className="relative w-full">
        <Swiper modules={[Autoplay]} autoplay>
          <SwiperSlide>
            <StaticImage
              src="../../images/Desktop/02.main_banner.png"
              alt="banner1"
              className="w-full"
              placeholder="blurred"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../images/Desktop/banner-2.jpg"
              alt="banner2"
              className="w-full"
              placeholder="blurred"
            />
          </SwiperSlide>
        </Swiper>
        <a
          href="#first"
          onClick={scrollTo}
          className="absolute down-arrow z-10"
          style={{ bottom: "20%", left: "10%" }}
        >
          <StaticImage
            src="../../images/icons/down.png"
            alt="down"
            width={27}
            placeholder="blurred"
          />
        </a>
      </div>
      <div className="flex items-stretch" id="first">
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("index.title1")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-xl text-center mb-10"
              style={{ letterSpacing: "0.5em" }}
            >
              <span className="block mb-3">{t("index.title1Des1")}</span>
              <span className="block mb-3">{t("index.title1Des2")}</span>
              <span className="block mb-3">{t("index.title1Des3")}</span>
            </p>
            <Link
              className="bg-black py-5 rounded-md text-center text-white text-lg"
              style={{ width: 230, letterSpacing: "0.3em" }}
              to="/about"
            >
              {t("index.title1Link")}
            </Link>
          </div>
        </div>
        <div className="flex-1 relative">
          <StaticImage
            src="../../images/Desktop/02.banner_1.png"
            alt="banner1"
            className="w-full"
            placeholder="blurred"
          />
        </div>
      </div>
      <div className="flex items-stretch">
        <div className="flex-1">
          <StaticImage
            src="../../images/Desktop/02.banner_2.png"
            alt="banner2"
            className="w-full"
            placeholder="blurred"
          />
        </div>
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("index.title2")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-xl text-center mb-10"
              style={{ letterSpacing: "0.5em" }}
            >
              <span className="block mb-3">{t("index.title2Des1")}</span>
              <span className="block mb-3">{t("index.title2Des2")}</span>
              <span className="block mb-3">{t("index.title2Des3")}</span>
              <span className="block mb-3">{t("index.title2Des4")}</span>
            </p>
            <Link
              className="bg-black py-5 rounded-md text-center text-white text-lg"
              style={{ width: 230, letterSpacing: "0.3em" }}
              to="/sorghum"
            >
              {t("index.title2Link")}
            </Link>
          </div>
        </div>
      </div>
      <div className="flex items-stretch">
        <div className="flex-1">
          <div className="flex flex-col justify-center items-center px-12 py-24 h-full">
            <h2
              className="text-2xl font-normal"
              style={{ letterSpacing: "0.5em" }}
            >
              {t("index.title3")}
            </h2>
            <div
              className="mt-6 mb-7 bg-gold"
              style={{ height: 2, width: 100 }}
            ></div>
            <p
              className="font-light text-xl text-center mb-10"
              style={{ letterSpacing: "0.5em" }}
            >
              <span className="block mb-3">{t("index.title3Des1")}</span>
            </p>
            <Link
              className="bg-black py-5 rounded-md text-center text-white text-lg"
              style={{ width: 230, letterSpacing: "0.3em" }}
              to="/location"
            >
              {t("index.title3Link")}
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <StaticImage
            src="../../images/Desktop/02.banner_1.png"
            alt="banner1"
            className="w-full"
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  )
}

export default DesktopIndex
